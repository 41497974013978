import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { Author } from "app-types/template";
import PlainHeader from "components/ui/base/PlainHeader";
import FeatureGrid from "components/ui/base/FeatureGrid";
import { COMMUNITY_PATH } from "constants/paths";
import { getAccountPicturePath } from "utils/path";

interface TemplatesPageProps {
  data: {
    allCommunityAuthors: {
      nodes: Author[];
    };
  };
  location: Location;
}

export default ({ data, location }: TemplatesPageProps) => {
  const { t } = useTranslation();

  const authors = data.allCommunityAuthors.nodes;

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.community.seo.title")}
        description={t("pages.community.seo.description")}
        image={{
          relativePath: "meta/success-stories-from-customerly-customers.jpg",
          alt: "Success Stories from Customerly",
        }}
      />
      <PlainHeader
        title={t("pages.community.title")}
        description={t("pages.community.description")}
        maxWidth={900}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Community",
            path: "/community/",
          },
        ]}
      />

      <FeatureGrid
        centered
        mediumImages
        roundedImages
        sections={authors.map((author) => {
          return {
            title: author.public_name,
            image: {
              alt: author.public_name,
              src: getAccountPicturePath(author.account_id),
            },
            links: [
              {
                text: t("pages.community.seeTemplates"),
                path: `${COMMUNITY_PATH}/${author.username}/`,
              },
            ],
          };
        })}
      />
    </GlobalWrapper>
  );
};
export const templatesPageQuery = graphql`
  query {
    allCommunityAuthors {
      nodes {
        account_id
        bio
        public_name
        username
        website
      }
    }
  }
`;
